import { format } from 'date-fns';
import React, { useEffect, useState } from "react";
import { ParseJwt } from '../../../core/utils/functions'
import Api from '../services/CodigosServices'
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import Loading from "../../../core/utils/loading";

const Codigos = () => {

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();

    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(false);
    const [data, setData] = useState([]);

    function generateUniqueCode() {
        const now = new Date();

        const year = now.getFullYear().toString().slice(-2);
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        const randomPart = Math.floor(100 + Math.random() * 900);

        const uniqueCode = `${year}${month}${day}${seconds}${randomPart}`;

        return uniqueCode;
    }

    const handleGenerateCode = () => {
        const newCode = generateUniqueCode();
        setValue('codigo', newCode);
    };

    const handdleAddCode = async (datos) => {
        const newData = {
            ...datos,
            usuarioId: user.sub
        }
        const response = await Api.CrearCodigo(newData);
        reset();
        setData([...data, response.data]);
    }

    const loadData = async () => {
        setLoading(true);
        const response = await Api.ObtenerCodigos();
        if (!response.success) {
            setLoading(false);
            setError(true);
            return;
        }
        setData(response.data);
        setLoading(false);
    }

    useEffect(() => {
        const dataUser = ParseJwt(localStorage.getItem('token'));
        setUser(dataUser);
        loadData().then();
    }, []);

    return (
        <>
            <ToastContainer />
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Codigos</h1>
            </div>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card border-0 shadow-sm">
                        <div className="card-header border-0">
                            <h3>Generar codigo</h3>
                            <div className="card-options">...</div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-8">
                                    <form onSubmit={handleSubmit(handdleAddCode)} >
                                        <div className="input-group mb-3">
                                            <input {...register("codigo", { required: "El codigo es obligatorio" })} placeholder="Codigo formulario" type="text" className={`form-control ${errors.codigo ? 'is-invalid' : ''}`} id="codigo" disabled/>
                                            <button onClick={handleGenerateCode} className="btn btn-primary btn-outline-secondary" type="button" id="button-addon2" name="Generar Código">
                                                <i className="bi bi-lightning-charge-fill text-white"></i>
                                            </button>
                                            <div className="invalid-feedback">
                                                {errors.codigo?.message}
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <button className="btn btn-primary text-center" type="submit">
                                                Guardar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </div>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
            <div className="row mt-5">
                <div className="col-md-12">
                    <div className="card border-0 shadow-sm">
                        <div className="card-header border-0">
                            <h3>Códigos generados</h3>
                        </div>
                        <div className="card-body">
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                            <div>
                                {
                                    loading ? (
                                        <Loading />
                                    ) : (
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                <thead className="table-dark">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Codigo</th>
                                                        <th scope="col">Estado</th>
                                                        <th scope="col">Fecha creación</th>
                                                        {/* <th scope="col" className='text-center'><i className="bi bi-trash"></i></th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.id}</td>
                                                            <td>{item.codigo}</td>
                                                            <td>{item.estado}</td>
                                                            <td>{format(new Date(item.fechaRegistro), 'dd/MM/yyyy')}</td>
                                                            {/* <td className='text-center text-danger'>
                                                                <i className="bi bi-trash fw-bold" style={{ cursor: 'pointer' }}></i>
                                                            </td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </>
    )
}

export default Codigos;