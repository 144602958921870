import { toast } from "react-toastify";
import { config } from "../../../core/config/config";

const base_url = config.baseUrl;
const endpoint = "/codigos";

async function CrearCodigo(dataCode) {

    const data = {
        ...dataCode
    }

    try {
        const url = `${base_url + endpoint}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        const result = await response.json();

        if (!response.ok) {
            let msj = result.message
            throw new Error(msj);
        }

        if (response.ok) {
            toast.success('Bien', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return { success: true, data: result };
        }

    } catch (error) {
        toast.error(error.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        return { success: false, message: error.message };
    }
}

async function ObtenerCodigos() {
    try {
        const token = localStorage.getItem("token");

        const headers = {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }
        
        const url = `${base_url + endpoint}`;
        const response = await fetch(url, {
            method: "GET",
            headers: headers
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result.message || 'Error al obtener los datos');
        }

        return { success: true, data: result };

    } catch (error) {
        toast.error(error.message || 'Ha ocurrido un error', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        return { success: false, message: error.message };
    }
}

async function EliminarCodigo(codigoId) {
    const data = {
        ...dataCode
    }

    try {
        const url = `${base_url + endpoint}`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        const result = await response.json();

        if (!response.ok) {
            let msj = result.message
            throw new Error(msj);
        }

        if (response.ok) {
            toast.success('Bien', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return { success: true, data: result };
        }

    } catch (error) {
        toast.error(error.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        return { success: false, message: error.message };
    }
}

export default {
    CrearCodigo,
    ObtenerCodigos
};